.home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .parallax {
    width:100%;
    min-height: 30vh;
    background-image: url('../assets/banner-cropped.jpg');
    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .company-overview {
    padding: 1vw;
    max-width: 60vw; /* Optional: Limit the width of the text area */
    text-align: left; /* Align text to the left */
    height:100vh;
    font-family: "EB Garamond", serif;
  }
  
  .company-overview h2 {
    color: #475467; /* Same color as the header */
    font-family: "EB Garamond", serif;
    font-size: 28px;
  }
  
  .company-overview p {
    font-family: "EB Garamond", serif;
    font-size: 18px;
    line-height: 1.6;
    color: #333;
  }