.about-overview {
    margin: 40px auto;
    max-width: 800px;
    text-align: left;
    padding: 20px;
}

.about-overview h2 {
    color: #475467; /* Same color as the header */
    font-family: "EB Garamond", serif;
    font-size: 28px;
  }

.about-overview p {
    font-family: "EB Garamond", serif;
    font-size: 18px;
    line-height: 1.6;
    color: #333;
  }
