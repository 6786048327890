.team-page {
    text-align: center;
    padding: 20px;
    margin: 40px auto;
    max-width: 75vw;
    text-align: left;
    padding: 20px;
    font-family: "EB Garamond", serif;
  }

  .team-page h2 {
    color: #475467; /* Same color as the header */
    font-family: "EB Garamond", serif;
    font-size: 28px;
  }
  
  .team-section {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    margin-top: 20px;
  }
  
  .team-member {
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 15px;
    text-align: center;
    background-color: #f9f9f9;
  }
  
  .member-image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 15px;
  }
  
  h1 {
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
  }
  
  h3 {
    margin: 10px 0;
    font-size: 18px;
  }
  
  p {
    color: #777;
    font-size: 14px;
  }
  