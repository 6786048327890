a {
    text-decoration: none !important; /* No underline by default */
}
  
a:hover {
    text-decoration: underline !important;
    text-decoration-color: #475467 !important;
}

.materials-overview {
    margin: 40px auto;
    max-width: 800px;
    text-align: left;
    padding: 20px;
}

.materials-overview h2 {
    color: #475467; /* Same color as the header */
    font-family: "EB Garamond", serif;
    font-size: 28px;
  }

.materials-overview p {
    font-family: "EB Garamond", serif;
    font-size: 18px;
    line-height: 1.6;
    color: #333;
  }
