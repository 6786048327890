.main-header {
  background-color: #475467;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 12vh;
  padding: 0 20px;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 800px;
}

.logo {
  width: 15vw;
  max-width: 150px;
  height: auto;
}

.navbar {
  display: flex;
}

.nav-links {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

.nav-links li {
  margin-left: 20px;
}

.nav-links a {
  color: white;
  text-decoration: none;
  font-family: "EB Garamond", serif;
  font-size: 24px;
}

.nav-links a:hover {
  text-decoration-line: underline;
}

/* Hamburger menu */
.hamburger {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 50px;
  height: 25px;
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 20px;
}

.hamburger .bar {
  height: 2px;
  width: 100%;
  background-color: white;
  border-radius: 2px;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .navbar {
      visibility: hidden;
      position: absolute;
      top: 12vh;
      right: 0;
      background-color: #475467;
      width: 100%;
      flex-direction: column;
      align-items: center;
      padding: 10px 0;
  }

  .navbar.open {
      display: flex !important;
      visibility: visible; /* Force navbar to display when open */
  }

  .nav-links {
      flex-direction: column;
      width: 100%;
  }

  .nav-links li {
      margin: 10px 0;
  }

  .nav-links a {
      font-size: 20px;
  }

  .hamburger {
      display: flex;
  }

  .logo {
      width: 30vw; /* Scales down the logo for mobile */
      max-width: 100px; /* Limit the max width */
  }
}